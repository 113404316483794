<template>
    <v-row justify="center">
        <v-col cols="12" sm="10" md="8">
            <v-row>
                <v-col cols="12">
                    <v-btn
                        text
                        color="text lighten-1"
                        link
                        :to="{ name: 'Library' }"
                    >
                        <v-icon class="mr-2">
                            fa-solid fa-arrow-left
                        </v-icon>
                        {{ $t(`library.returnBack`) }}
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <project-info-card
                        v-if="project.id"
                        :project="project"
                        type="library"
                        card-width="100%"
                        :card-heigth="cardHeight"
                        :media-height="mediaHeight"
                        media-width="540"
                    >
                        <template v-if="project.library_file" #viewAction>
                            <v-btn
                                icon
                                plain
                                small
                                link
                                target="_blank"
                                :href="project.library_file"
                            >
                                <v-icon size="18" color="text lighten-1">
                                    fa-solid fa-eye
                                </v-icon>
                            </v-btn>
                        </template>
                    </project-info-card>
                </v-col>
                <v-col v-if="recommended.length > 0" cols="12" class="mt-10">
                    <div>
                        <h3>{{ $t(`library.recommendatedVideos`) }}</h3>
                        <v-row>
                            <v-col
                                v-for="item in recommended"
                                :key="item.id"
                                cols="12"
                                md="2"
                                class="mt-4"
                            >
                                <project-info-card
                                    :project="item"
                                    type="library"
                                    card-width="240"
                                    :card-heigth="200"
                                    :media-height="150"
                                    media-width="214"
                                >
                                    <template #viewAction>
                                        <v-btn
                                            icon
                                            plain
                                            small
                                            link
                                            :href="item.library_file"
                                        >
                                            <v-icon
                                                size="18"
                                                color="text lighten-1"
                                            >
                                                fa-solid fa-eye
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                </project-info-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { getAllLibraries, getLibrary } from "@/api/libraryApi.js";
import { CONFIG } from "@/common/config.js";

import ProjectInfoCard from "@/components/InfoCard/ProjectInfoCard.vue";
import ViewProjectBtn from "@/components/InfoCard/ViewProjectBtn.vue";
export default {
    name: "LibraryInfo",

    components: {
        ProjectInfoCard,
        ViewProjectBtn,
    },

    data() {
        return {
            project: {},
            recommended: [],
            cardHeight: CONFIG.card_heigth,
            mediaHeight: CONFIG.media_height,
        };
    },

    methods: {
        async handleLibraries() {
            const { response, error } = await withAsync(getAllLibraries);

            if (error) {
                return;
            }

            this.recommended = response.data.data.filter(
                (item) =>
                    item.status == "publish" && item.id != this.$route.params.id
            );
        },
        async handleLibrary(id) {
            const payload = {
                id,
            };

            const { response, error } = await withAsync(getLibrary, payload);

            if (error) {
                return;
            }

            this.project = response.data.data;
        },
    },

    created() {
        this.handleLibraries();
        this.handleLibrary(this.$route.params.id);
    },
};
</script>

<style lang="scss" scoped></style>
